<template>
  <div class="widgetContainer bg-white addAccount widgetContainer--center">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="goToPreviousScreen" />
        <p class="title">
          {{ $t('selectAcc_screenTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <div class="listWithActionsv2 border-on">
        <ul>
          <li
            v-if="$personalChecking === true"
            @click="personalAccountSelection">
            <div class="details">
              <p class="label">
                Personal checking
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
          <li
            v-if="getBusinessList.length === 0 && $businessChecking === true"
            @click="addBusiness">
            <div class="details">
              <p class="label">
                Add a Business
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>

      <div
        class="listWithActionsv2 border-on"
        v-if="$businessChecking === true && getBusinessList.length !== 0">
        <p class="header-2">
          {{ $t('selectAcc_accTitle') }}
        </p>
        <ul>
          <li
            v-for="(business, index) in getBusinessList"
            @click="selectBusiness(business)"
            :key="index">
            <div class="details">
              <p class="label">
                {{ business.legalName }}
              </p>
            </div>
            <div class="action">
              <span class="icon-Arrow-small-right" />
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="addAccount__business">
                <p class="addAccount__business__header header-title">{{$t('selectAcc_addBusiTitle')}}</p>
                <ul>
                    <li class="addAccount__business__add">
                        <div class="icon">
                            <span class="icon-card"></span>
                        </div>
                        <div class="info">
                            <p class="title">{{$t('selectAcc_businessCell_title')}}</p>
                            <p class="description">{{$t('selectAcc_businessCell_desc', {value1: 'Solid'})}}</p>
                        </div>
                        <span class="icon-arrow-right"></span>
                    </li>
                </ul>
            </div> -->
    </div>
  </div>
</template>

<script>
import accountMixin from '../mixins/account';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'AddAccount',
  mixins: [accountMixin],
  created() {
    this.fetchBusinessList();
  },
  computed: {
    ...mapGetters('kyb', ['getBusinessList']),
    ...mapGetters('account', ['getCreateAccountData'])
  },
  methods: {
    ...mapActions('kyb', ['listAllBusiness']),
    ...mapMutations('account', [
      'updateCreateAccountData',
      'setAddAccountBackPage'
    ]),
    fetchBusinessList() {
      const loader = this.showLoader();
      this.listAllBusiness()
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    selectBusiness(business) {
      this.$store.commit('dashboard/setSelectedBusiness',business);
      if (business.kyb && business.kyb.status === 'approved') {
        if (!this.isHellosignEnabled || business.disclosureStatus === 'completed' || ['soleProprietor', 'singleMemberLLC'].includes(
          business.entityType
        ) ) {
          let businessId = business.id;
          this.updateCreateAccountData({
            businessId,
            type: 'businessChecking'
          });
          setTimeout(() => {
            this.$router.push('/account/setup');
          }, 0);
        } else {
          this.$router.push('/kyb/businessStatus');
        }
      } else {
        if (business.kyb.status === 'notStarted') {
          this.addBusiness();
        } else {
          this.$router.push('/kyb/businessStatus');
        }
      }
    },
    personalAccountSelection() {
      this.updateCreateAccountData({
        businessId: null,
        type: 'personalChecking'
      });
      setTimeout(() => {
        this.$router.push('/account/setup');
      }, 0);
    },
    addBusiness() {
      this.setAddAccountBackPage(null);
      this.$router.push('/kyb/businessDetails');
    },
    next() {
      if (
        this.getCreateAccountData &&
        typeof this.getCreateAccountData === 'object' &&
        this.getCreateAccountData.businessId
      ) {
        this.$router.push('/account/setup');
      }
    }
  }
};
</script>

<style lang="scss">
.addAccount {

  .navigationHeader {
    padding-left: 30px;
  }
  .widgetContainer__body {
    padding: 30px;
  }
  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
  }

  &.bg-white {
    .widgetContainer__body {
      padding-top: 32px;
    }
  }
}
</style>
